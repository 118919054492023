import { AuditLog } from './interfaces';

export enum types {
  SEND_ANALYTICS = '@@analytics/SEND_ANALYTICS',

  SEND_ANALYTICS_REQUEST = '@@analytics/SEND_ANALYTICS_REQUEST',
  SEND_ANALYTICS_SUCCESS = '@@analytics/SEND_ANALYTICS_SUCCESS',
  SEND_ANALYTICS_FAILURE = '@@analytics/SEND_ANALYTICS_FAILURE',
}

export interface SendAnalyticsRequest {
  type: typeof types.SEND_ANALYTICS_REQUEST;
}

export interface SendAnalyticsSuccess {
  type: typeof types.SEND_ANALYTICS_SUCCESS;
}

export interface SendAnalyticsFailure {
  type: typeof types.SEND_ANALYTICS_FAILURE;
}

export interface SendAnalyticsAction {
  type: typeof types.SEND_ANALYTICS;
  data: AuditLog;
}

export const sendAnalyticsRequest = () => ({
  type: types.SEND_ANALYTICS_REQUEST,
});

export const sendAnalyticsSuccess = () => ({
  type: types.SEND_ANALYTICS_SUCCESS,
});

export const sendAnalyticsFailure = () => ({
  type: types.SEND_ANALYTICS_FAILURE,
});

export const sendAnalytics = (data: AuditLog): SendAnalyticsAction => ({
  type: types.SEND_ANALYTICS,
  data,
});

export type AnalyticsActions =
  | SendAnalyticsRequest
  | SendAnalyticsSuccess
  | SendAnalyticsFailure
  | SendAnalyticsAction;
